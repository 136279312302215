<!-- 账户 -->
<template>
  <el-container class="home-container">
    <!-- 侧边栏 -->
    <!-- 头部区域 -->
    <el-header>
      <div>
        <span>景德镇市智慧旅游统一票务管理系统</span>
      </div>
      <h6>{{ userName }}, 你好</h6>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <div class="toggle-button">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          default-active = "/account"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409eff"
          :router="true"
        >
          <el-menu-item :index="resolvePath(menu.菜单路由)" @click="saveNavState(menu.菜单路由)"
                        v-for="menu in menuList" :key="menu.ID">
            <template slot="title">
              <i :class="menu.菜单图标"></i>
              <span>{{menu.菜单名称}}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- 卡片视图 -->
        <el-card>
          <!-- 搜索与添加区域 -->
          <el-row :gutter="20">
            <el-col :span="9">
              <el-input
                placeholder="请输入内容"
                v-model="queryInfo.query"
                clearable
                @clear="seach"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="seach"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" @click="addDialogVisible = true;title='添加员工'">添加员工</el-button>
            </el-col>
          </el-row>

          <!-- 表格区域 -->
          <el-table :data="staffList" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="name" label="管理员名称"> </el-table-column>
            <el-table-column prop="type_str" label="类型"> </el-table-column>
            <el-table-column prop="tel" label="电话"> </el-table-column>
            <el-table-column prop="areaName" label="隶属名称"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template v-slot:default="scope">
                <el-switch
                  v-model="scope.row.status"
                  @change="updateInfo(scope.row.ID, scope.row.status)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
              <template v-slot:default="scope">
                <!-- {{scope.row}} -->
                <!-- 作用域插槽 -->
                <!-- 修改按钮 scope.row.id -->
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="small"
                  @click="showEditDialog(scope.row.ID)"
                ></el-button>
                <!-- 删除按钮 -->
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  @click="deleteStaff(scope.row.ID)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>

          <!-- 添加员工对话框 -->
          <el-dialog
            :title="title"
            :visible.sync="addDialogVisible"
            width="50%"
            @closed="addHandleClose"
          >
            <el-form
              :model="addForm"
              :rules="formRules"
              ref="addFormRef"
              label-width="100px"
            >
              <el-form-item label="管理员名称" prop="name">
                <el-input v-model="addForm.name"></el-input>
              </el-form-item>
              <el-form-item label="账号" prop="account">
                <el-input v-model="addForm.account" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input :type="typePwd" v-model="addForm.password" autocomplete="off"
                ><el-button
                  slot="append"
                  icon="el-icon-view"
                  circle
                  @click="showPwd"
                ></el-button
                ></el-input>
              </el-form-item>
              <el-form-item label="员工电话" prop="tel">
                <el-input v-model="addForm.tel"></el-input>
              </el-form-item>
              <el-form-item label="员工隶属" prop="areaID">
                <el-select v-model="addForm.areaID" filterable>
                  <el-option v-for="item in areaList" :key="item.ID" :label="item['景区名称']" :value="item.ID"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="员工身份" prop="type">
                <el-radio-group v-model="addForm.type" >
                  <el-radio v-for="item in [{label:'渠道商',value:'6'},{label:'售票员',value:'1'},{label:'验票员',value:'2'},{label:'操作员',value:'3'},{label:'管理员',value:'4'}]" :label="item['value']" :key="item.value">{{item['label']}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <span slot="footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="title==='添加员工'?addStaff():changeStaff()">确 定</el-button>
          </span>
          </el-dialog>

        </el-card>

      </el-main>
    </el-container>

  </el-container>
</template>



<script>
import Qs from 'qs'

export default {
  // name: "staff",
  data() {
    return {
      queryInfo: {
        query: "",
        // 当前的页数
        pagenum: 1,
        pagesize: 5,
      },
      menuList:[{菜单名称:'报表',菜单图标:'el-icon-document',ID:1,菜单路由:'/report'},{菜单名称:'账号',菜单图标:'el-icon-s-custom',ID:2,菜单路由:'/account'}],
      total: 0,
      staffList: [],
      areaList:[],
      title:'添加员工',
      // 添加员工弹框是否展示
      addDialogVisible: false,
      userName: window.sessionStorage.getItem("userName"),

      addForm: {
        name: "",
        account: "",
        password: "",
        tel: "",
        EntityStatus: "",
        type: "",
        areaID:'',
        areaName:'',
        menuList:[]
      },
      // 展示密码
      typePwd: "password",
      formRules:{
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        account: [{ required: true, message: "请输入登录账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入登录密码", trigger: "blur" }, { min: 6, message: '长度最少6个字符', trigger: 'blur' }],
        areaID: [{ required: true, message: "请选择隶属景区", trigger: 'change'  }],
      }
    };
  },
  created() {
    this.getStaffList();
    this.getAreaList()
  },
  methods: {
    logout() {
      window.localStorage.clear();
      this.$router.push("/").catch(() => {
      });
    },
    //保存连接的激活状态
    saveNavState(activePath){
      window.sessionStorage.setItem('activePath',activePath)
    },
    resolvePath(routePath) {
      if (routePath) {
        return routePath
      } else {
        return this.basePath
      }
    },
    getAreaList(){
      this.$axios({
        method: "get",
        url: "/scenery/getScenceList?pageSize=9999&currentPage=1"
      }).then(res=>{
        this.areaList=res.data
      })
    },
    seach(){
      this.queryInfo.pagenum=1
      this.getStaffList()
    },
    getStaffList() {
      this.$axios({
        method: "post",
        url: "/manager/staff",
        data: this.queryInfo,
      })
        .then((res) => {
          console.log(res);
          // console.log(res.data.employees);
          this.total = res.total;
          this.staffList = res.data.map(v=>{
            v.status=v.EntityStatus===1
            let type_str=''
            switch (Number(v.type)){
              case 1:type_str='售票员';break;
              case 2:type_str='验票员';break;
              case 4:type_str='管理员';break;
              case 3:
              case 5:type_str='操作员';break;
              case 6:type_str='渠道商';break;
              default:type_str='未知';break
            }
            v.type_str=type_str
            return v
          });
          // console.log(this.staffList);
          // console.log(res.data.employees);
        })
        .catch((err) => err);
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getStaffList();
    },
    //关闭添加员工弹框
    addHandleClose() {
      this.confirmPwd = "";
      this.addForm={
        name: "",
        account: "",
        password: "",
        tel: "",
        EntityStatus: "",
        type: "",
        areaID: '',
        areaName:'',
        menuList:[]
      }
      this.$refs.addFormRef.resetFields();
    },
    // 展示密码
    showPwd() {
      this.typePwd = this.typePwd === "password" ? "text" : "password";
    },
    // 添加员工
    addStaff() {
      // const formData = new FormData()
      // formData.append('role', 1)

      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.$axios({
          method: "post",
          url: "/manager/addStaff",
          data: this.addForm,
          // headers:{
          //   'Content-Type': 'application/x-www-form-urlencoded'
          // }
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加用户成功");
              this.addDialogVisible = false;
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => err);
      });
    },
    //删除员工
    deleteStaff(id) {
      // console.log(id);
      this.$confirm("此操作将删除该员工, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$axios({
            method: "post",
            url: "/manager/editStaff",
            data:{
              ID:id,
              isDelete:1
            }
          }).then(res=>{
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }

          })

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //状态改变函数
    updateInfo(id, status) {
      status = status == false ? 0 : 1;
      // console.log(status);
      let emp_status = { status: status };
      console.log(emp_status);

      this.$axios({
        method: "post",
        url: "/manager/editStaff",
        data:{
          ID:id,
          update:1,
          status:status
        }
      }).then(res=>{
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getStaffList();
        } else {
          this.$message.warning(res.msg);
        }
      })
        .catch((err) => err);
    },
    //修改员工信息
    changeStaff() {
      console.log(9999)
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.$axios({
          method: "post",
          url: "/manager/editStaff",
          data: this.addForm,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "更新员工信息成功!",
              });
              this.addDialogVisible = false;
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    },
    //监听对话框打开与关闭
    showEditDialog(id) {
      this.title='编辑员工'
      this.addDialogVisible = true;
      this.addForm=Object.assign({},this.staffList.find(v=>v.ID===id))
    },
  },
};
</script>

<style scoped>
.home-container {
  height: 100vh;
}

.el-table .warning-row {
  background: #c1d59c;
}

.el-table .success-row {
  background: #6587b4;
}

.el-table .header-row {
  background: #5d5e5c;
  text-align: center;
}

.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.el-aside {
  background-color: #333744;

}

.el-main {
  background-color: #eaedf1;
}

.el-aside .toggle-button {
  background-color: #4A5064;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;

}
</style>




